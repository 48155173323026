<template>
  <v-dialog
    v-model="modalOpen"
    class="email-settings"
    :fullscreen="$vuetify.breakpoint.xs"
    transition="dialog-bottom-transition"
    attach="body"
    lazy
  >
    <v-card>
      <v-card-text
        class="dialog-show-checklists-content"
      >
        <checklist-viewer
          v-for="id in collections"
          :key="`show_checklist_${id}`"
          :settings="displaySettings()"
          :settings-ignore-default="true"
          :collection-id="id"
          :guid="`READ_ONLY_DISPLAY_${id}`"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import { mapGetters } from 'vuex'
import ChecklistViewer from '@/components/ChecklistBuilder/Viewer/ChecklistViewer.vue'
import dialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'DialogShowChecklists',
  components: {
    ChecklistViewer
  },
  mixins: [dialogMixin],
  props: [
    'dialog',
    'collections',
    'builder',
    'cell',
    'checklist',
    'dataViewer'
  ],
  /* computed: {
    ...mapGetters({
      online: 'online/online'
    })
  }, */
  methods: {
    displaySettings() {
      return {
        ONLINE: !!this.online,
        TRANSLATION: this.lang,
        DEBUG_LOG: this.builder.getSetting('DEBUG_LOG'),
        DISPLAY_MODE: true,
        VALUE_SELECTOR: 'READ_ONLY_DISPLAY',
        READ_ONLY: !this.cell.settings.display_checklists_editable,
        ON_CELL_CLICKED: (data) => {
          const builder = this.builder
          const index = this.checklist.index
          const previousValue = this.dataViewer.value
          const settings = data.settings || {}
          let value
          let options = []
          let tempValue = []
          switch (this.cell.type) {
            case 'checkbox':
              if (settings.on_click_callback_single) {
                value = /^(|0|undefined|null|false)$/gi.test(
                  settings.on_click_callback_single_value
                )
              }
              if (settings.on_click_callback_multi) {
                value = !/^(|0|undefined|null|false)$/gi.test(
                  settings.on_click_callback_multi_value_0
                )
              }
              break
            case 'dropdown': case 'multiselect':
              options = this.cell.settings.dropdown_builder || []
              if (settings.on_click_callback_single) {
                tempValue = (settings.on_click_callback_single_value || '').split(',')
              }
              if (settings.on_click_callback_multi) {
                tempValue = (settings.on_click_callback_multi_value_0 || '').split(',')
              }
              tempValue = tempValue.map((item) => {
                return parseInt(item, 10)
              })
              value = this.getSelectedOptionsValue(options, tempValue)
              break
            case 'combine_selects': case 'combine_selects_custom':
              options = this.cell.settings.dropdown_builder || []
              value = {}

              // Dropdown 1
              if (settings.on_click_callback_single) {
                tempValue = (settings.on_click_callback_single_value || '').split(',')
              }
              if (settings.on_click_callback_multi) {
                tempValue = (settings.on_click_callback_multi_value_0 || '').split(',')
              }
              value['select_0'] = this.getSelectedOptionsValue(options, tempValue)

              // Dropdown 2
              options = this.cell.settings.dropdown_builder_1 || []
              if (settings.on_click_callback_multi) {
                tempValue = (settings.on_click_callback_multi_value_1 || '').split(',')
              }
              value['select_1'] = this.getSelectedOptionsValue(options, tempValue)

              // Dropdown 3 oder Anzeige
              if (this.cell.type === 'combine_selects') {
                if (settings.on_click_callback_multi) {
                  value.result = settings.on_click_callback_multi_value_2 || ''
                }
              } else {
                options = this.cell.settings.dropdown_builder_2 || []
                if (settings.on_click_callback_multi) {
                  tempValue = (settings.on_click_callback_multi_value_2 || '').split(',')
                }
                value['select_2'] = this.getSelectedOptionsValue(options, tempValue)
              }
              break
            default:
              if (settings.on_click_callback_single) {
                value = settings.on_click_callback_single_value || ''
              }
              if (settings.on_click_callback_multi) {
                value = settings.on_click_callback_multi_value_0 || ''
              }
              break
          }
          builder.evalValues({
            index: index,
            dataEditor: this.cell,
            dataViewer: this.dataViewer,
            cloneIndex: 0,
            encode: false,
            saveCallback: () => {
              if (settings.on_click_callback_close) {
                this.dialog.active = false
              }
            },
            ignoreAutoSaveSetting: false,
            event: {},
            value: value,
            previousValue: previousValue
          })
        }
      }
    },
    closeDialog(event) {
      this.$emit('closeDialog', event)
    },
    getSelectedOptionsValue(options, value) {
      let result = {}
      value = value.map((item) => {
        return parseInt(item, 10)
      })
      for (let i = 0, option; (option = options[i]); i++) {
        if (value.indexOf(i) > -1) {
          if (typeof option === 'string') {
            option = {
              value: option,
              color: ''
            }
          }
          result = {
            id: i,
            label: this.builder.getLocalization(option, 'value') || ''
          }
        }
      }
      return result
    }
  }
}
</script>

<style>
  .dialog-show-checklists-content {
    font-family: Roboto,sans-serif;
    line-height: 1;
    text-align: initial;
  }
</style>
