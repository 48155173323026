<template>
  <div>
    <v-btn
      :class="`${classes}`"
      :disabled="disabled"
      :title="lang('ChecklistBuilder.titles.display_checklists')"
      data-quick-button-action-view="display_checklists"
      type="button"
      small
      @click="onClick"
    >
      <v-icon>
        mdi-format-list-checks
      </v-icon>
    </v-btn>
    <dialog-show-checklists
      v-if="!disabled"
      v-model="dialog.active"
      :dialog="dialog"
      :builder="data.ChecklistBuilder"
      :cell="cell"
      :data-viewer="dataViewer"
      :checklist="checklist"
      :collections="displayCollections"
      @closeDialog="onCloseDialog"
    />
  </div>
</template>

<script>
import DialogShowChecklists from '@/components/ChecklistBuilder/Dialog/ShowChecklists.vue'

export default {
  name: 'ChecklistCellButtonTypeDisplayChecklists',
  components: {
    DialogShowChecklists
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'data'
  ],
  data() {
    return {
      dialog: {
        active: false
      }
    }
  },
  computed: {
    dataViewer() {
      const builder = this.data.ChecklistBuilder
      return builder.getCellDataViewer(this.checklist.index, this.x, this.y) || {}
    },
    classes() {
      return this.state.classes
    },
    displayCollections() {
      const collections = this.cell.settings.display_checklists || []
      return collections
    },
    disabled() {
      return this.state.disabled
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog.active = false
    },
    onClick() {
      if (!Array.isArray(this.displayCollections)) {
        return
      }
      if (!this.displayCollections.length) {
        return
      }
      this.dialog.active = true
    }
  }
}
</script>
